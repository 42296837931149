import { Box, Tab, Tabs } from "@mui/material";

import { useState } from "react";

import ReactionIcon from "components/feed/FeedCard/base/reactions/ReactionIcon";
import ReactionUserList from "components/feed/FeedCard/base/reactions/ReactionUserList";
import {
  CcoReaction,
  LatestReactions,
  ReactionCounts,
  StreamReaction,
} from "lib/motorcade/types";

type Props = {
  reactionCounts: ReactionCounts;
  reactions: LatestReactions;
};

export default function ReactionTabs({ reactionCounts, reactions }: Props) {
  const [value, setValue] = useState("all");
  const reactionLists = {
    all: Object.keys(reactions).reduce(
      (acc: StreamReaction[], curr) => [
        ...acc,
        ...(reactions[curr as CcoReaction] || []),
      ],
      []
    ),
    ...reactions,
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
        <Tab label="All" sx={{ minWidth: "auto" }} value="all" />
        {Object.keys(reactionCounts).map((kind) => (
          <Tab
            key={kind}
            label={
              <ReactionIcon
                count={reactionCounts[kind as keyof ReactionCounts]}
                kind={kind as CcoReaction}
              />
            }
            sx={{ minWidth: "auto" }}
            value={kind}
          />
        ))}
      </Tabs>
      <Box height="100%" pl={3}>
        <ReactionUserList
          list={reactionLists[value as CcoReaction] || []}
          showReactionBadge={value === "all"}
        />
      </Box>
    </Box>
  );
}
