import { gql } from "@apollo/client";

import { EVENT_FRAGMENT } from "graphql/events/fragments";
import { USER_FRAGMENT } from "graphql/user/fragments";

import { FEED_GROUP_FRAGMENT } from "./fragments";

export const FEED_POST_CREATE = gql`
  mutation feedPostCreate($input: FeedPostCreateInput!, $first: Int) {
    feedPostCreate(input: $input) {
      id
      audience
      group {
        ...FeedGroup_Fragment
      }
      attachment {
        url
        mediaType
      }
      mentionedUsers {
        ...User_Fragment
      }
    }
  }
  ${FEED_GROUP_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const FEED_POST_UPDATE = gql`
  mutation feedPostUpdate($input: FeedPostUpdateInput!, $first: Int) {
    feedPostUpdate(input: $input) {
      id
      audience
      group {
        ...FeedGroup_Fragment
      }
      attachment {
        url
        mediaType
      }
      mentionedUsers {
        ...User_Fragment
      }
    }
  }
  ${FEED_GROUP_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const FEED_POST_DELETE = gql`
  mutation feedPostDelete($id: ID!) {
    feedPostDelete(id: $id)
  }
`;

export const STREAM_FLAG_ACTIVITY = gql`
  mutation streamFlagActivity($input: StreamFlagActivityInput!) {
    streamFlagActivity(input: $input)
  }
`;

export const STREAM_FLAG_REACTION = gql`
  mutation streamFlagReaction($input: StreamFlagReactionInput!) {
    streamFlagReaction(input: $input)
  }
`;

export const FEED_EVENT_CREATE = gql`
  mutation feedEventCreate($input: FeedEventCreateInput!) {
    feedEventCreate(input: $input) {
      id
    }
  }
`;

export const FEED_EVENT_DELETE = gql`
  mutation feedEventDelete($id: ID!) {
    feedEventDelete(id: $id)
  }
`;

export const FEED_EVENT_UPDATE = gql`
  mutation feedEventUpdate($input: FeedEventUpdateInput!) {
    feedEventUpdate(input: $input) {
      id
      ...FeedEvent_Fragment
    }
  }
  ${EVENT_FRAGMENT}
`;

export const MEDIA_UPLOAD = gql`
  mutation mediaUpload($dataUri: String!) {
    mediaUpload(input: { dataUri: $dataUri, type: FEED_IMAGE })
  }
`;

export const FEED_COMMENT_CREATE = gql`
  mutation feedCommentCreate($input: FeedCommentCreateInput!) {
    feedCommentCreate(input: $input) {
      id
    }
  }
`;

export const FEED_COMMENT_UPDATE = gql`
  mutation feedCommentUpdate($input: FeedCommentUpdateInput!) {
    feedCommentUpdate(input: $input) {
      id
    }
  }
`;

export const FEED_GROUP_PIN = gql`
  mutation FeedGroupPin($input: FeedGroupPinInput!) {
    feedGroupPin(input: $input)
  }
`;

export const FEED_GROUP_UNPIN = gql`
  mutation FeedGroupUnpin($input: FeedGroupPinInput!) {
    feedGroupUnpin(input: $input)
  }
`;
