import { gql } from "@apollo/client";

export const EVENT_FRAGMENT = gql`
  fragment FeedEvent_Fragment on FeedEvent {
    id
    createdAt
    updatedAt
    cta
    description
    endTime
    link
    startDate
    startTime
    timezone
    type
    title
    activityId
    audience
    resourceCta
    resourceLink
    author {
      id
    }
    host {
      id
      snapshot {
        name
        avatar
      }
    }
    group {
      id
      name
      slug
    }
    attachment {
      url
    }
  }
`;
