import markdownInit from "lib/markdown/markdownInit";

function recurseNodes(node, results) {
  if (node.type === "link") {
    const match =
      /\/profile\/([\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12})(?:\?|#|\/|$)/i.exec(
        node.url
      );
    if (match) {
      results.push(match[1]);
    }
  }
  if (node.children) {
    node.children.forEach((n) => recurseNodes(n, results));
  }
}

export default function parseMentions(message) {
  const AST = markdownInit.Parser(message);
  const mentions = [];
  recurseNodes(AST, mentions);
  return Array.from(new Set(mentions).values());
}
