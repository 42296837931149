import { SvgIcon, SvgIconProps } from "@mui/material";

export default function Concerned(props?: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <circle cx="12.51019" cy="11.07475" opacity="0.3" r="8" />
      <path d="m0.28526,-0.97337l24,0l0,24l-24,0l0,-24z" fill="none" />
      <circle cx="16.01019" cy="8.57475" r="1.5" />
      <circle cx="9.01019" cy="8.57475" r="1.5" />
      <path d="m12.50019,1.07475c-5.52,0 -9.99,4.48 -9.99,10s4.47,10 9.99,10c5.53,0 10.01,-4.48 10.01,-10s-4.48,-10 -10.01,-10zm0.01,18c-4.42,0 -8,-3.58 -8,-8s3.58,-8 8,-8s8,3.58 8,8s-3.58,8 -8,8z" />
      <rect
        height="1.5"
        transform="rotate(-15 12.5 13.7954)"
        width="8"
        x="9"
        y="13.2954"
      />
    </SvgIcon>
  );
}
