import { Edit, Flag, MoreHoriz } from "@mui/icons-material";
import { Menu, MenuItem, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";

import ReportReactionDialog from "components/feed/dialogs/ReportDialog/ReportReaction";
import useMe from "hooks/useMe";
import { StreamReaction } from "lib/motorcade/types";

type Props = {
  reaction: StreamReaction;
  onEdit?: () => void;
};

export default function ReactionCardContextMenu({ reaction, onEdit }: Props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);

  const { id: meId } = useMe();

  const isMyReaction = reaction.user_id === meId;
  const reportableReactions = [
    "answer",
    "comment",
    "community_expertpanel_answer",
    "reply",
  ];
  const editableReactions = ["comment", "reply"];
  const showReportButton =
    !isMyReaction && reportableReactions.includes(reaction.kind);
  const showEditButton =
    isMyReaction && editableReactions.includes(reaction.kind);
  const hideContextMenu = !showReportButton && !showEditButton;

  const handleClose = () => setAnchorEl(null);

  if (hideContextMenu) return null;

  return (
    <>
      <IconButton
        aria-label="context-menu"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClick={(e) => e.stopPropagation()}
        onClose={() => setAnchorEl(null)}
      >
        {showReportButton && (
          <MenuItem
            onClick={() => {
              setIsReportDialogOpen(true);
              handleClose();
            }}
          >
            <Flag sx={{ mr: 1 }} />
            <Typography variant="body2">Report content</Typography>
          </MenuItem>
        )}

        {showEditButton && (
          <MenuItem
            onClick={() => {
              onEdit();
              handleClose();
            }}
          >
            <Edit sx={{ mr: 1 }} />
            <Typography variant="body2">Edit {reaction.kind}</Typography>
          </MenuItem>
        )}
      </Menu>

      <ReportReactionDialog
        activityId={reaction.activity_id}
        isOpen={isReportDialogOpen}
        reactionId={reaction.parent || reaction.id}
        replyId={reaction.parent ? reaction.id : undefined}
        onClose={() => setIsReportDialogOpen(false)}
      />
    </>
  );
}
