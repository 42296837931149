import IconButton from "@mui/material/IconButton";

import Link from "components/icons/Link";
import cls from "lib/common/cls";

import { getSelectionText, isLink } from "../helpers";
import { pluginKey as editLinkPluginKey } from "../plugins/editLink";
import { MenuButtonComponentProps } from "../types";

export type EditLinkPluginState = {
  active: boolean;
  title?: string;
  href?: string;
  editable?: boolean;
};

export default function InsertLinkButton({
  className,
  setState,
  state,
  view,
  ...props
}: MenuButtonComponentProps) {
  const { tr } = state;

  return (
    <IconButton
      className={cls([className, { active: isLink(state) }])}
      size="large"
      title="Add Link"
      onClick={() => {
        const updatedState: EditLinkPluginState = { active: true };
        const link = isLink(state);
        if (link) {
          updatedState.title = link.title;
          updatedState.href = link.href;
          updatedState.editable = true;
        } else {
          updatedState.title = getSelectionText(state);
        }
        tr.setMeta(editLinkPluginKey, { updatedState });
        setState(state.apply(tr));

        if (view) {
          view.focus();
        }
      }}
      {...props}
    >
      <Link fontSize="small" />
    </IconButton>
  );
}
