import { Avatar, Badge, Box, Typography } from "@mui/material";

import Link from "next/link";

import ReactionIcon from "components/feed/FeedCard/base/reactions/ReactionIcon";
import { StreamReaction } from "lib/motorcade/types";

type Props = {
  reaction: StreamReaction;
  showReactionBadge: boolean | undefined;
};

export default function ReactionListItem({
  reaction,
  showReactionBadge = false,
}: Props) {
  const { kind, user } = reaction;
  const { avatar, name, company } = user.data;

  return (
    <Link
      href={`/profile/${user.id}`}
      style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}
    >
      {showReactionBadge ? (
        <Badge
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={<ReactionIcon kind={kind} />}
          overlap="circular"
        >
          <Avatar alt={name} sizes="small" src={avatar} />
        </Badge>
      ) : (
        <Avatar alt={name} sizes="small" src={avatar} />
      )}
      <Box display="flex" flexDirection="column" ml={2}>
        <Typography noWrap color="textPrimary" variant="subtitle2">
          {name}
        </Typography>
        <Typography noWrap color="textSecondary" variant="caption">
          {company}
        </Typography>
      </Box>
    </Link>
  );
}
