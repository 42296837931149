import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "graphql/user/fragments";

export const FEED_GROUP_FRAGMENT = gql`
  fragment FeedGroup_Fragment on FeedGroup {
    id
    slug
    name
    description
    imageUrl
    leadership {
      user {
        ...User_Fragment
      }
      type
      role
      capabilities
    }
    followers(first: $first) {
      edges {
        node {
          ...User_Fragment
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
    totalFollowers
    type
  }
  ${USER_FRAGMENT}
`;
