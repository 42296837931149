import { CcoReaction, Verb } from "lib/motorcade/types";

export const TIMELINE_FEED_GROUP = "agg_timeline";
export const GENERAL_FEED_GROUP = "public";
export const PROFILE_FEED_GROUP = "user";
export const GROUP_FEED_GROUP = "group";
export const GROUP_PINNED_FEED_GROUP = "group_pin";
export const NOTIFICATION_FEED_GROUP = "notification";
export const CCO_REACTIONS: Lowercase<CcoReaction>[] = [
  "like",
  "celebrate",
  "insightful",
  "concerned",
];
export const DEPRECATED_ACTIVITY_VERBS = ["newsstory"];
export const DELETABLE_ACTIVITY_VERBS = ["post", "poll", "event"];
export const EDITABLE_ACTIVITY_VERBS = ["post", "poll", "event"];
export const PINNABLE_ACTIVITY_VERBS = ["post", "poll"];
export const REPORTABLE_ACTIVITY_VERBS = ["post", "poll", "event"];
export const READABLE_ACTIVITY_VERB: Record<Verb, string> = {
  article: "Article",
  event: "Event",
  expertpanel: "Expert Panel",
  follow: "Follow",
  group_expertpanel: "Group Expert Panel",
  join: "Join",
  poll: "Post",
  post: "Post",
  thirdparty: "Third Party",
};
