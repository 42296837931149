import { Box, Theme, useMediaQuery } from "@mui/material";

import ReactionListItem from "components/feed/FeedCard/base/reactions/ReactionListItem";
import { StreamReaction } from "lib/motorcade/types";

type Props = {
  list: StreamReaction[];
  showReactionBadge?: boolean;
};

export default function ReactionUserList({ list, showReactionBadge }: Props) {
  const isSmDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  if (list?.length === 0) return null;
  return (
    <Box height={isSmDown ? "100%" : 345} overflow="auto" pt={3}>
      {list?.map((reaction) => (
        <ReactionListItem
          key={reaction.id}
          reaction={reaction}
          showReactionBadge={showReactionBadge}
        />
      ))}
    </Box>
  );
}
