import { ThumbUpOffAlt } from "@mui/icons-material";
import { Button, IconButton, Paper, Popper, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

import { reactionOptions } from "components/feed/FeedCard/base/reactions/index";
import theme from "styles/theme";

type Props = {
  onClick: (kind: string) => void;
  pendoClassName?: string;
  selectedReaction?: string;
  showPopper?: boolean;
  size?: "small" | "medium" | "large";
};

const defaultReactionKind = "like";

export default function ReactionButton({
  onClick,
  pendoClassName,
  selectedReaction,
  showPopper = true,
  size = "large",
}: Props) {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLSpanElement) | null
  >(null);
  const reactionKind = selectedReaction || defaultReactionKind;
  const StartIcon = selectedReaction
    ? reactionOptions[selectedReaction].icon
    : null;
  const reactionColor =
    reactionOptions[selectedReaction]?.color || theme.palette.primary.main;

  const handleMouseEnter = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    if (!showPopper) return;
    setAnchorEl(e.currentTarget);
  };

  const handleMouseLeave = () => {
    if (!showPopper) return;
    setAnchorEl(null);
  };

  return (
    <Box
      width="fit-content"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Popper
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        placement="top-start"
        sx={{ zIndex: 2000 }}
      >
        <Paper elevation={8}>
          {Object.keys(reactionOptions).map((kind) => {
            const ReactionIcon = reactionOptions[kind].icon;
            return (
              <Tooltip
                key={kind}
                placement="top"
                title={reactionOptions[kind].label}
              >
                <IconButton
                  size="large"
                  onClick={() => {
                    onClick(kind);
                    setAnchorEl(null);
                  }}
                >
                  <ReactionIcon
                    style={{ color: reactionOptions[kind].color }}
                  />
                </IconButton>
              </Tooltip>
            );
          })}
        </Paper>
      </Popper>

      <Button
        className={pendoClassName}
        size={size}
        startIcon={StartIcon ? <StartIcon /> : <ThumbUpOffAlt />}
        style={{
          color: reactionColor,
          textTransform: "capitalize",
        }}
        onClick={() => onClick(reactionKind)}
      >
        {reactionKind}
      </Button>
    </Box>
  );
}
