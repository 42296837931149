import Close from "@mui/icons-material/Close";
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

function Wrapper({ active, anchorEl, children, handleClose, state }) {
  return anchorEl ? (
    <ClickAwayListener
      onClickAway={() => {
        handleClose(state);
      }}
    >
      <Popper
        disablePortal
        keepMounted
        anchorEl={anchorEl}
        open={active}
        placement="top-start"
        sx={{ zIndex: (theme) => theme.zIndex.modal }}
      >
        <Paper>{children}</Paper>
      </Popper>
    </ClickAwayListener>
  ) : (
    <Box m="0 auto 25px" width="fit-content">
      {children}
    </Box>
  );
}

export default function LinkFormPopup({
  active,
  anchorEl = null,
  canSubmit,
  children,
  editingLink,
  handleAddLink,
  handleClose,
  handleSubmit,
  state,
}) {
  if (!active) {
    return null;
  }

  return (
    <Wrapper
      active={active}
      anchorEl={anchorEl}
      handleClose={handleClose}
      state={state}
    >
      <Box
        sx={{
          border: "1px solid #000",
          borderRadius: "8px",
          maxWidth: "450px",
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          p="2px 14px 0 29px"
        >
          <Typography variant="subtitle1">Insert link</Typography>

          <IconButton size="large" onClick={() => handleClose(state)}>
            <Close color="primary" />
          </IconButton>
        </Box>

        <Box p="0 29px 10px">{children}</Box>

        <Box display="flex" justifyContent="end" p="3px 29px 22px">
          <Button
            color="primary"
            disabled={!canSubmit}
            size="medium"
            sx={{
              padding: "7px 14px",
              "&.MuiButton-outlined.Mui-disabled": {
                border: "1px solid rgba(0, 0, 0, 0.05)",
              },
            }}
            variant="contained"
            onClick={handleSubmit(handleAddLink)}
          >
            {`${editingLink ? "Save" : "Insert"}`}
          </Button>
        </Box>
      </Box>
    </Wrapper>
  );
}
