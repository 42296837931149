import { gql } from "@apollo/client";

import {
  EXPERT_PANEL_ANSWER_FRAGMENT,
  EXPERT_PANEL_QUESTION_FRAGMENT,
} from "graphql/expert-panels/fragments";

export const EXPERT_PANEL_ANSWER_QUESTION = gql`
  mutation expertPanelAnswerQuestion($input: ExpertPanelAnswerQuestionInput!) {
    expertPanelAnswerQuestion(input: $input) {
      ...ExpertPanelQuestion_Fragment
    }
  }
  ${EXPERT_PANEL_QUESTION_FRAGMENT}
`;

export const EXPERT_PANEL_ANSWER_LIKE = gql`
  mutation expertPanelAnswerLike($answerId: ID!) {
    expertPanelAnswerLike(answerId: $answerId) {
      ...ExpertPanelAnswer_Fragment
    }
  }
  ${EXPERT_PANEL_ANSWER_FRAGMENT}
`;

export const EXPERT_PANEL_ANSWER_UNLIKE = gql`
  mutation expertPanelAnswerUnlike($answerId: ID!) {
    expertPanelAnswerUnlike(answerId: $answerId) {
      ...ExpertPanelAnswer_Fragment
    }
  }
  ${EXPERT_PANEL_ANSWER_FRAGMENT}
`;
