import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

import Close from "components/icons/Close";

export default function LinkFormModal({
  active,
  canSubmit,
  children,
  handleAddLink,
  handleClose,
  handleSubmit,
  state,
}) {
  return (
    <Dialog fullWidth maxWidth="sm" open={active}>
      <DialogTitle component={Typography} variant="body1Semibold">
        Add link
      </DialogTitle>

      <IconButton
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={() => handleClose(state)}
      >
        <Close />
      </IconButton>

      <DialogContent dividers>
        <Box display="flex" flexDirection="column" gap={2}>
          {children}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleClose(state)}>Cancel</Button>
        <Button
          disabled={!canSubmit}
          variant="contained"
          onClick={handleSubmit(handleAddLink)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
