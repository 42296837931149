import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

import { reactionOptions } from "components/feed/FeedCard/base/reactions";
import ThumbUpAlt from "components/icons/ThumbUpAlt";

type Props = {
  count: number;
};

export default function ExpertPanelAnswerLkeCount({ count = 0 }: Props) {
  if (count === 0) {
    return null;
  }

  return (
    <>
      <Box
        borderRadius="50%"
        height={4}
        marginRight={0.5}
        sx={{ backgroundColor: (theme) => theme.palette.primary.main }}
        width={4}
      />

      <Box alignItems="center" display="flex" gap={0.5}>
        <Box
          sx={{
            backgroundColor: `${reactionOptions.like?.backgroundColor}`,
            borderRadius: "50%",
            height: "24px",
            width: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: (theme) =>
              `1px solid ${theme.palette.primary.contrastText}`,
          }}
        >
          <ThumbUpAlt
            style={{
              color: reactionOptions.like?.color,
              fontSize: "18px",
            }}
          />
        </Box>

        <Typography color="textSecondary" variant="subtitle2">
          {count}
        </Typography>
      </Box>
    </>
  );
}
