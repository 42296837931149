import { useMutation } from "@apollo/client";
import { useRef } from "react";

import Snackbar from "components/shared/Snackbar";
import ReactionButton from "components/ui/ReactionButton";
import {
  EXPERT_PANEL_ANSWER_LIKE,
  EXPERT_PANEL_ANSWER_UNLIKE,
} from "graphql/expert-panels/mutations";
import useMe from "hooks/useMe";
import useReaction from "lib/motorcade/hooks/useReaction";
import { AlertCallback } from "lib/motorcade/types";

function FeedExpertPanelReactionButton({ answer }) {
  const snackbarRef = useRef(null);

  const me = useMe();

  const alertCallback: AlertCallback = ({ message, type, title }) => {
    snackbarRef?.current?.callSnackbar(message, type, title);
  };
  const { toggleEPAnswerLike } = useReaction(answer, alertCallback);

  const hasLiked = answer.reaction?.latest_children.like?.some(
    (like) => like.user.id === me.id
  );

  return (
    <>
      <Snackbar reference={snackbarRef} />
      <ReactionButton
        pendoClassName="pendo_web-like"
        selectedReaction={hasLiked && "like"}
        showPopper={false}
        size="small"
        onClick={() => toggleEPAnswerLike()}
      />
    </>
  );
}

function GraphExpertPanelReactionButton({ answer }) {
  const snackbarRef = useRef(null);
  const [likeAnswer] = useMutation(EXPERT_PANEL_ANSWER_LIKE);
  const [unlikeAnswer] = useMutation(EXPERT_PANEL_ANSWER_UNLIKE);

  const handleReaction = async (ans) => {
    const mutation = ans.hasLiked ? unlikeAnswer : likeAnswer;
    const mutationPropertyName = ans.hasLiked
      ? "expertPanelAnswerUnlike"
      : "expertPanelAnswerLike";

    try {
      await mutation({
        variables: {
          answerId: ans.id,
        },
        optimisticResponse: {
          [mutationPropertyName]: {
            ...ans,
            likeCount: ans.likeCount + (ans.hasLiked ? -1 : 1),
            hasLiked: !ans.hasLiked,
          },
        },
      });
    } catch (err) {
      const errMessage = err?.message?.toLowerCase().includes("admin")
        ? err.message
        : "Something went wrong, try again.";
      snackbarRef?.current?.callSnackbar(errMessage, "error");
    }
  };

  return (
    <>
      <Snackbar reference={snackbarRef} />
      <ReactionButton
        pendoClassName="pendo_web-like"
        selectedReaction={answer.hasLiked && "like"}
        showPopper={false}
        size="small"
        onClick={() => handleReaction(answer)}
      />
    </>
  );
}

export default function ExpertPanelAnswerReactionButton({
  answer,
  context = "graph",
}) {
  if (context === "feed") {
    return <FeedExpertPanelReactionButton answer={answer} />;
  }

  return <GraphExpertPanelReactionButton answer={answer} />;
}
