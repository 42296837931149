import { Box } from "@mui/material";

import { reactionOptions } from "components/feed/FeedCard/base/reactions/index";

type Props = {
  kind: string;
  count?: number;
};

export default function ReactionIcon({ count, kind }: Props) {
  const Icon = reactionOptions[kind]?.icon;
  return (
    <Box alignItems="center" display="flex" gap="6px">
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "50%",
        }}
      >
        <span
          style={{
            backgroundColor: `${reactionOptions[kind]?.color}${25}`,
            borderRadius: "50%",
            height: "24px",
            width: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon
            style={{ color: reactionOptions[kind]?.color, fontSize: "18px" }}
          />
        </span>
      </Box>
      {count && <span>{count}</span>}
    </Box>
  );
}
