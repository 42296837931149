import { useMutation } from "@apollo/client";

import { useApp } from "lib/common/appProvider";
import { STREAM_FLAG_REACTION } from "lib/motorcade/graphql/mutations";

import ReportDialog from "./ReportDialog";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  activityId: string;
  reactionId?: string;
  replyId?: string;
};

type ReportInput = {
  reason: string;
  streamActivityId: string;
  streamReactionId?: string;
  streamReplyId?: string;
};

export default function ReportReactionDialog({
  isOpen,
  onClose,
  activityId,
  reactionId,
  replyId,
}: Props) {
  const snackbarRef = useApp("snackbarRef");

  const [reportReaction, { loading }] = useMutation(STREAM_FLAG_REACTION);

  const onSubmit = async (reason: string) => {
    const input: ReportInput = {
      streamActivityId: activityId,
      reason,
      streamReactionId: reactionId,
      streamReplyId: replyId,
    };

    try {
      await reportReaction({
        variables: { input },
      });
      snackbarRef?.current?.callSnackbar(
        "Report successfully submitted",
        "success"
      );
      onClose();
    } catch (error) {
      snackbarRef?.current?.callSnackbar(error.message, "error");
      onClose();
    }
  };

  return (
    <ReportDialog
      isLoading={loading}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
}
