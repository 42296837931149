import parseMentions from "lib/markdown/parseMentions";

const getUserStub = ({ author }) => {
  const { id: myId, profile, name } = author;
  return {
    id: myId,
    data: {
      avatar: profile?.avatar,
      name: name?.fullName,
      company: profile?.company?.name,
    },
  };
};

export const getVoteReactionStub = ({ author, choices }) => {
  const time = new Date().toISOString();
  return {
    id: `pending:${time}`,
    kind: "vote",
    created_at: time,
    data: {
      choices,
    },
    user: getUserStub({ author }),
  };
};

const getGroupCollectionStub = ({ group }) => {
  const { id, name, type } = group;
  return {
    id,
    data: {
      name,
      type,
    },
  };
};

export function getBaseActivityWithReactionsStub() {
  return {
    reaction_counts: {},
    own_reactions: {},
    latest_reactions: {},
  };
}

const getBaseActivityStub = ({ author, input }) => {
  const time = new Date().toISOString();
  return {
    id: `pending:${time}`,
    time,
    audience: input.audience,
    groupRef: input?.group
      ? getGroupCollectionStub({ group: input?.group })
      : undefined,
    actor: getUserStub({ author }),
    ...getBaseActivityWithReactionsStub(),
  };
};

export const getPostActivityStub = ({ author, input }) => {
  const baseActivityStub = getBaseActivityStub({ author, input });
  return {
    ...baseActivityStub,
    verb: "post",
    object: {
      id: baseActivityStub.id,
      collection: "post",
      mediaUrl: input?.attachment?.url,
      mediaType: input?.attachment?.mediaType,
      data: {
        body: input?.markdown,
      },
    },
  };
};

export const getReactionStub = ({ author, kind }) => {
  const time = new Date().toISOString();

  return {
    id: `pending:${time}`,
    kind,
    created_at: new Date().toISOString(),
    user: getUserStub({ author }),
  };
};

export const getPollActivityStub = ({ author, input }) => {
  const baseActivityStub = getBaseActivityStub({ author, input });
  return {
    ...baseActivityStub,
    verb: "poll",
    object: {
      id: baseActivityStub.id,
      collection: "poll",
      data: {
        postText: input.postText,
        question: input.question,
        options: input.options,
        closeAt: input.closeAt,
        results: [],
      },
    },
  };
};

export const getEventActivityStub = ({ author, input }) => {
  const baseActivityStub = getBaseActivityStub({ author, input });

  const attachmentData = input?.dataUri
    ? { mediaType: "image", mediaUrl: input.dataUri }
    : {};

  return {
    ...baseActivityStub,
    ...attachmentData,
    host:
      input.host &&
      getUserStub({
        author: {
          id: input?.host?.userId,
          name: {
            fullName: input?.host?.name,
          },
          profile: {
            avatar: input?.host?.avatar,
          },
        },
      }),
    verb: "event",
    object: {
      id: baseActivityStub.id,
      collection: "event",
      data: input,
    },
  };
};

export const getResponseStub = ({ author, input, type }) => {
  const mentionedUserIds = parseMentions(input.markdown);
  const mentions = mentionedUserIds.map((id) => `USER:${id}`);
  const time = new Date().toISOString();

  return {
    id: `pending:${time}`,
    children_counts: {},
    created_at: time,
    data: {
      body: input.markdown,
      mediaType: input.mediaType,
      mediaUrl: input.mediaFile,
      mentions,
    },
    kind: type,
    latest_children: {},
    updated_at: time,
    user: getUserStub({ author }),
  };
};

export function stubAnswer({ author, input }) {
  const time = new Date().toISOString();

  return {
    id: `pending:${time}`,
    kind: "answer",
    created_at: time,
    data: {
      body: input.answer,
    },
    user: getUserStub({ author }),
    user_id: author.id,
  };
}
