import { Close } from "@mui/icons-material";
import { Box, Dialog, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

import ReactionTabs from "components/feed/FeedCard/base/reactions/ReactionTabs";
import Celebration from "components/icons/Celebration";
import Concerned from "components/icons/Concerned";
import Lightbulb from "components/icons/Lightbulb";
import ModeComment from "components/icons/ModeComment";
import ThumbUpAlt from "components/icons/ThumbUpAlt";
import {
  CcoReaction,
  LatestReactions,
  ReactionCounts,
  ReactionOption,
} from "lib/motorcade/types";

type Props = {
  commentCount?: number;
  latestReactions: LatestReactions;
  reactionCounts: ReactionCounts;
};

export const reactionOptions: Record<string, ReactionOption> = {
  like: {
    label: "LIKE",
    icon: ThumbUpAlt,
    color: "#0A66C2",
    backgroundColor: "#C2D9F0",
  },
  celebrate: {
    label: "CELEBRATE",
    icon: Celebration,
    color: "#488E29",
    backgroundColor: "#D2E3CA",
  },
  insightful: {
    label: "INSIGHTFUL",
    icon: Lightbulb,
    color: "#FF9800",
    backgroundColor: "#FBD3A7",
  },
  concerned: {
    label: "CONCERNED",
    icon: Concerned,
    color: "#660F95",
    backgroundColor: "#D9C4E5",
  },
};

const MAX_AMOUNT_LIST_ITEMS = 15;

const getReactionsNameList = (reactionList: LatestReactions): JSX.Element[] => {
  if (Object.keys(reactionList).length === 0) {
    return [];
  }

  return Object.entries(reactionList).flatMap(
    ([_, reactions]): JSX.Element[] => {
      if (!reactions.length) return [];

      return reactions.slice(0, MAX_AMOUNT_LIST_ITEMS).map(
        (reaction, index): JSX.Element => (
          <p
            key={reaction.id}
            style={{ fontSize: "10px", lineHeight: "5px", textAlign: "center" }}
          >
            {index === MAX_AMOUNT_LIST_ITEMS - 1 &&
            reactions.length > MAX_AMOUNT_LIST_ITEMS
              ? `and ${reactions.length - MAX_AMOUNT_LIST_ITEMS} more...`
              : reaction.user.data.name}
          </p>
        )
      );
    }
  );
};

export default function Reactions({
  commentCount,
  latestReactions,
  reactionCounts = {},
}: Props) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const filteredReactionCounts = {} as ReactionCounts;
  Object.keys(reactionCounts).forEach((key) => {
    if (reactionCounts[key]) {
      filteredReactionCounts[key] = reactionCounts[key];
    }
  });

  const showReactions =
    commentCount || Object.keys(filteredReactionCounts).length > 0;

  if (!showReactions) return null;

  const totalReactions = Object.keys(filteredReactionCounts).reduce(
    (acc, curr) => acc + filteredReactionCounts[curr as CcoReaction],
    0
  );

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          mb={1}
          p={3}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          <Typography color="textPrimary" variant="h3">
            Reactions
          </Typography>
          <Close
            sx={{ cursor: "pointer" }}
            onClick={() => setIsDialogOpen(false)}
          />
        </Box>
        <Box height="100%" overflow="hidden">
          <ReactionTabs
            reactionCounts={filteredReactionCounts}
            reactions={latestReactions}
          />
        </Box>
      </Dialog>

      <Box
        alignItems="center"
        display="flex"
        height="24px"
        justifyContent="space-between"
      >
        <Box alignItems="center" display="flex">
          {totalReactions > 0 && (
            <>
              {Object.keys(filteredReactionCounts).map((kind) => {
                const ReactionIcon = reactionOptions[kind]?.icon;
                if (
                  !ReactionIcon ||
                  filteredReactionCounts[kind as keyof ReactionCounts] < 1
                )
                  return null;
                return (
                  <Box
                    key={kind}
                    sx={{
                      backgroundColor: `${reactionOptions[kind]?.backgroundColor}`,
                      borderRadius: "50%",
                      height: "24px",
                      width: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: (theme) =>
                        `1px solid ${theme.palette.primary.contrastText}`,
                      "&:not(:first-child)": {
                        marginLeft: "-6px",
                      },
                    }}
                  >
                    <ReactionIcon
                      style={{
                        color: reactionOptions[kind]?.color,
                        fontSize: "18px",
                      }}
                    />
                  </Box>
                );
              })}
              <Tooltip arrow title={getReactionsNameList(latestReactions)}>
                <Typography
                  color="textSecondary"
                  sx={{ ml: 0.5, cursor: "pointer" }}
                  variant="body2"
                  onClick={() => setIsDialogOpen(true)}
                >
                  {totalReactions}
                </Typography>
              </Tooltip>
            </>
          )}
        </Box>

        {commentCount > 0 && (
          <Box alignItems="center" display="flex" gap={0.5}>
            <ModeComment
              sx={{
                color: (theme) => theme.palette.text.secondary,
                fontSize: "16px",
              }}
            />
            <Typography color="textSecondary" variant="body2">
              {commentCount}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}
