import { Chip } from "@mui/material";

import LocalFireDepartment from "components/icons/LocalFireDepartment";

const tagBackgroundColors = {
  Answered: { backgroundColor: "rgba(46, 125, 50, 0.3)" },
  "Closing soon": { backgroundColor: "rgba(211, 47, 47, 0.30)" },
  New: { backgroundColor: "rgba(239, 108, 0, 0.30)" },
  Spotlight: { backgroundColor: "rgba(2, 136, 209, 0.30)" },
  Trending: { backgroundColor: "transparent", icon: <LocalFireDepartment /> },
};

export default function ExpertPanelTagChip({ tag }) {
  return (
    <Chip
      icon={tagBackgroundColors[tag]?.icon}
      label={tag}
      size="small"
      sx={{
        backgroundColor: tagBackgroundColors[tag]?.backgroundColor,
      }}
    />
  );
}
