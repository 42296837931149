import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";

import { useState } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => void;
  isLoading: boolean;
};

export default function ReportDialog({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
}: Props) {
  const router = useRouter();
  const [reason, setReason] = useState("");

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <DialogTitle>Report Content</DialogTitle>
      <DialogContent sx={{ "&.MuiDialogContent-root": { paddingTop: "5px" } }}>
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Reason for reporting (optional)"
          placeholder="Briefly explain why you are reporting this content."
          size="small"
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        p={3}
      >
        <Link
          passHref
          href={{
            pathname: "/guidelines",
            query: {
              url: router.asPath,
            },
          }}
        >
          See Community Guidelines
        </Link>
        <Box alignItems="center" display="flex">
          <Button sx={{ mr: 1 }} onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={() => onSubmit(reason)}
          >
            Report
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
}
