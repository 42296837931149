import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  insertLink,
  normalizeUrl,
  setCursorToSelectionEnd,
} from "components/shared/inputs/Editor/helpers";
import {
  defaultState as editLinkPluginDefaultState,
  pluginKey as editLinkPluginKey,
} from "components/shared/inputs/Editor/plugins/editLink";

import LinkFormModal from "./LinkFormModal";
import LinkFormPopup from "./LinkFormPopup";

export const linkSchema = z.object({
  href: z.string().min(1, { message: "Url is required" }),
  title: z.string(),
});

function Form({
  errors,
  hrefInputRef,
  setValue,
  titleInputRef,
  variant,
  watch,
}) {
  return (
    <>
      <TextField
        fullWidth
        id="title"
        inputRef={titleInputRef}
        label="Text"
        margin={variant === "modal" ? "none" : "dense"}
        name="title"
        size="small"
        value={watch("title")}
        onChange={(e) => setValue("title", e.target.value)}
      />

      <TextField
        fullWidth
        helperText={
          errors.href && <span>{errors?.href?.message as string}</span>
        }
        id="href"
        inputRef={hrefInputRef}
        label="Link"
        margin={variant === "modal" ? "none" : "dense"}
        name="href"
        size="small"
        value={watch("href")}
        onChange={(e) => setValue("href", e.target.value)}
      />
    </>
  );
}

export default function LinkForm({
  anchorEl = null,
  setState,
  state,
  variant = "popup",
  view,
}) {
  const titleInputRef = useRef(null);
  const hrefInputRef = useRef(null);

  const { active, href, title, editingLink } =
    editLinkPluginKey?.getState(state) || {};

  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: zodResolver(linkSchema),
    defaultValues: {
      href,
      title,
    },
  });

  const canSubmit = !!watch("href");

  const setFormValues = useCallback(
    (values) => {
      setValue("href", values.href);
      setValue("title", values.title);
    },
    [setValue]
  );

  // Resets the plugin state, form and focuses the editor
  const handleClose = (currentState) => {
    const tr = setCursorToSelectionEnd(currentState);
    tr.setMeta(editLinkPluginKey, {
      updatedState: editLinkPluginDefaultState,
    });
    setState(currentState.apply(tr));
    view.focus();
    reset();
  };

  const handleAddLink = (values) => {
    const newLinkString = values.title || values.href;
    const hrefNormalized = normalizeUrl(values.href);
    const tr = insertLink(state, {
      title: newLinkString,
      href: hrefNormalized,
      "data-edited-link": true,
    });
    const updatedState = state.apply(tr);
    handleClose(updatedState);
  };

  useEffect(() => {
    if (active) {
      // Set the initial form values from the editor content
      setFormValues({ href, title });

      if (!title) {
        titleInputRef?.current?.focus();
      } else {
        hrefInputRef?.current?.focus();
      }
    }
  }, [active, href, setFormValues, title]);

  const FormWrapper = variant === "modal" ? LinkFormModal : LinkFormPopup;

  return (
    <FormWrapper
      active={active}
      anchorEl={anchorEl}
      canSubmit={canSubmit}
      editingLink={editingLink}
      handleAddLink={handleAddLink}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      state={state}
    >
      <Form
        errors={errors}
        hrefInputRef={hrefInputRef}
        setValue={setValue}
        titleInputRef={titleInputRef}
        variant={variant}
        watch={watch}
      />
    </FormWrapper>
  );
}
