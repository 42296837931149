/* eslint-disable camelcase */
import { useMutation as useApolloMutation } from "@apollo/client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DefaultGenerics, EnrichedReaction } from "getstream";
import { useContext } from "react";

import {
  EXPERT_PANEL_ANSWER_LIKE,
  EXPERT_PANEL_ANSWER_UNLIKE,
} from "graphql/expert-panels/mutations";
import useMe from "hooks/useMe";
import { CCO_REACTIONS } from "lib/motorcade/constants";
import { StreamContext } from "lib/motorcade/providers/stream";
import { AlertCallback, CcoReaction, FeedActivity } from "lib/motorcade/types";
import { updateFeedActivity } from "lib/motorcade/utils/state";

import { NOTIFICATION_FEED_GROUP } from "../constants";
import { getReactionStub } from "../utils/stubs";

export default function useReaction(
  activity: FeedActivity,
  alertCallback: AlertCallback
) {
  const { streamClient, currentFeedQueryKey } = useContext(StreamContext);

  const me = useMe();
  const queryClient = useQueryClient();
  const [likeAnswer] = useApolloMutation(EXPERT_PANEL_ANSWER_LIKE);
  const [unlikeAnswer] = useApolloMutation(EXPERT_PANEL_ANSWER_UNLIKE);

  const { own_reactions, reaction_counts, latest_reactions } = activity;

  let myReactionKind: Lowercase<CcoReaction> | undefined;
  let myReaction: EnrichedReaction<DefaultGenerics> | undefined;

  if (own_reactions) {
    myReactionKind = CCO_REACTIONS.find((r) => own_reactions[r]);
    myReaction = myReactionKind
      ? own_reactions[myReactionKind]?.[0]
      : undefined;
  }

  async function streamAddReaction(kind: CcoReaction) {
    return streamClient?.reactions?.add(
      kind,
      activity.id,
      {
        kind,
        activityType: activity.verb,
        redirectUrl: `/activities/${activity.id}`,
      },
      {
        targetFeeds:
          activity.actor.id !== me.id
            ? [`${NOTIFICATION_FEED_GROUP}:${activity.actor.id}`]
            : [],
        targetFeedsExtraData: {
          parentId: activity.id,
        },
      }
    );
  }

  const { mutate: addReaction } = useMutation({
    mutationFn: async (kind: CcoReaction) => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });

      const pendingReaction = getReactionStub({
        author: me,
        kind,
      });

      const reactionCountsUpdate = {
        [kind]: reaction_counts?.[kind] ? reaction_counts[kind] + 1 : 1,
      };

      const ownReactionsUpdate = {
        [kind]: [pendingReaction],
      };

      const latestReactionsUpdate = {
        ...latest_reactions,
        [kind]: [...(latest_reactions?.[kind] || []), pendingReaction],
      };

      // Delete any existing reaction in state
      if (myReaction) {
        delete own_reactions?.[myReaction.kind];
        reactionCountsUpdate[myReaction.kind] = reaction_counts?.[
          myReaction.kind
        ]
          ? reaction_counts[myReaction.kind] - 1
          : 0;

        latestReactionsUpdate[myReaction.kind] = latestReactionsUpdate[
          myReaction.kind
        ].filter((r) => r.id !== myReaction?.id);
        if (latestReactionsUpdate[myReaction.kind].length === 0) {
          delete latestReactionsUpdate[myReaction.kind];
        }
      }

      queryClient.setQueryData([currentFeedQueryKey], (feed: FeedActivity) => {
        return updateFeedActivity({
          feed,
          activity,
          newData: {
            reaction_counts: {
              ...reaction_counts,
              ...reactionCountsUpdate,
            },
            own_reactions: {
              ...own_reactions,
              ...ownReactionsUpdate,
            },
            latest_reactions: latestReactionsUpdate,
          },
          queryKey: currentFeedQueryKey,
        });
      });

      // Delete any existing reaction from Stream
      if (myReaction) {
        await streamClient?.reactions?.delete(myReaction.id);
      }

      const newReaction = await streamAddReaction(kind);

      return { newReaction };
    },
    onSuccess: ({ newReaction }) => {
      if (!newReaction) return;

      queryClient.setQueryData([currentFeedQueryKey], (feed: unknown) => {
        const updatedReactions =
          activity?.latest_reactions?.[newReaction.kind]?.filter(
            (r) => !r.id.includes("pending")
          ) || [];

        return updateFeedActivity({
          feed,
          activity,
          newData: {
            own_reactions: {
              ...activity.own_reactions,
              [newReaction.kind]: [newReaction],
            },
            latest_reactions: {
              ...activity.latest_reactions,
              [newReaction.kind]: [...updatedReactions, newReaction],
            },
          },
          queryKey: currentFeedQueryKey,
        });
      });
    },

    onError: () => {
      alertCallback({
        message: "Unable to complete action. Please try again.",
        type: "error",
      });
    },
  });

  const { mutate: removeReaction } = useMutation({
    mutationFn: async () => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });
      const prevFeed = queryClient.getQueryData([currentFeedQueryKey]);

      if (myReaction) {
        delete own_reactions?.[myReaction.kind];
        if (latest_reactions?.[myReaction.kind]) {
          const updatedReactions = latest_reactions[myReaction.kind].filter(
            (reaction) => reaction.id !== myReaction?.id
          );

          if (updatedReactions.length === 0) {
            delete latest_reactions[myReaction.kind];
          } else {
            latest_reactions[myReaction.kind] = updatedReactions;
          }
        }

        queryClient.setQueryData([currentFeedQueryKey], (feed: unknown) => {
          if (!myReaction) return;

          return updateFeedActivity({
            feed,
            activity,
            newData: {
              reaction_counts: {
                ...reaction_counts,
                [myReaction.kind]: reaction_counts?.[myReaction.kind]
                  ? // eslint-disable-next-line no-unsafe-optional-chaining
                    reaction_counts?.[myReaction.kind] - 1
                  : 0,
              },
              own_reactions,
              latest_reactions,
            },
            queryKey: currentFeedQueryKey,
          });
        });

        await streamClient?.reactions?.delete(myReaction.id);
      }

      return { prevFeed };
    },
    onError: () => {
      alertCallback({
        message: "Unable to complete action. Please try again.",
        type: "error",
      });
    },
  });

  const { mutate: toggleEPAnswerLike } = useMutation({
    mutationFn: async () => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });

      const answer = activity.reaction;
      const hasLiked = answer.latest_children?.like?.some(
        (like) => like.user.id === me.id
      );

      const pendingReaction = getReactionStub({
        author: me,
        kind: "like",
      });

      answer.children_counts = {
        like: answer.children_counts?.like
          ? answer.children_counts.like + (hasLiked ? -1 : 1)
          : 1,
      };

      answer.latest_children = {
        like: [...(answer.latest_children?.like || [])],
      };

      if (hasLiked) {
        answer.latest_children.like = answer.latest_children.like.filter(
          (like) => like.user.id !== me.id
        );
      } else {
        answer.latest_children.like.push(pendingReaction);
      }

      queryClient.setQueryData([currentFeedQueryKey], (feed: FeedActivity) => {
        return updateFeedActivity({
          feed,
          activity,
          newData: {
            reaction: answer,
          },
          queryKey: currentFeedQueryKey,
        });
      });

      const mutation = hasLiked ? unlikeAnswer : likeAnswer;

      await mutation({
        variables: {
          answerId: answer.data.foreign_id,
        },
      });
    },

    onError: () => {
      alertCallback({
        message: "Unable to complete action. Please try again.",
        type: "error",
      });
    },
  });

  return {
    addReaction,
    myReaction,
    removeReaction,
    toggleEPAnswerLike,
  };
}
